import { setClients } from 'helpers/clients';
import useToast from 'hooks/useToast';
import { useAtom } from 'jotai';
import Keycloak from 'keycloak-js';
import { useEffect, useRef } from 'react';
import { keycloakAtom } from 'state/application';

export default function AppLogin({ children }: any) {
    const [keycloak, setKeycloak] = useAtom<Keycloak | null>(keycloakAtom);
    const refreshTokenIntervalRef = useRef<any>(null);

    const showToast = useToast();

    const initKeyCloak = async () => {
        const keycloak: Keycloak = new Keycloak({
            url: 'https://ums.the-nest.io/auth',
            realm: 'Emc2',
            clientId: 'emc2-new',
        });

        if (keycloak.authenticated) {
            return
        }

        const cachedToken = localStorage.getItem('kcToken');
        const cachedRefreshToken = localStorage.getItem('kcRefreshToken');

        const authenticated = await keycloak.init({
            onLoad: 'login-required',
            token: cachedToken || undefined,
            refreshToken: cachedRefreshToken || undefined,
            enableLogging: true,
            checkLoginIframe: true, // Enable iframe checking for silent login
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html' // Should match the configured URL for silent-check
        })

        setKeycloak(keycloak);

        console.log('authenticated', authenticated);

        if (authenticated) {
            console.log('User is authenticated');
        } else {
            console.log('User is not authenticated');
        }
    }

    useEffect(() => {
        if (keycloak?.authenticated) {

            if (keycloak.token) {
                localStorage.setItem('kcToken', keycloak.token);
            }
            if (keycloak.refreshToken) {
                localStorage.setItem('kcRefreshToken', keycloak.refreshToken);
            }

            function startTokenRefresh(keycloak: any) {
                if (refreshTokenIntervalRef.current) {
                    clearInterval(refreshTokenIntervalRef.current);
                }

                if (keycloak) {
                    refreshTokenIntervalRef.current = setInterval(() => {
                        keycloak
                            .updateToken(30) // Attempts to refresh the token if it expires in 30 seconds or less
                            .then((refreshed: boolean) => {
                                if (refreshed) {
                                    console.log('Token successfully refreshed');
                                } else {
                                    console.log('Token is still valid', new Date());
                                }
                            })
                            .catch(() => {
                                console.error('Failed to refresh the token, redirecting to login');
                                keycloak.logout(); // Redirect to login if refresh fails
                            });
                    }, 60000); // Token check interval
                }
            }

            console.log('User is authenticated');
            startTokenRefresh(keycloak)

            keycloak?.token && setClients(keycloak.token, showToast)

            return () => {
                console.log('cleanup AppLogin');
                if (refreshTokenIntervalRef.current) {
                    clearInterval(refreshTokenIntervalRef.current);
                }
            }
        }
    }, [keycloak])

    useEffect(() => {
        console.log('init AppLogin');

        initKeyCloak()
    }, []);

    if (!keycloak) {
        return <div style={{
            background: "#222", color: 'white', display: 'flex', alignContent: 'center', height: '100vh',
            width: '100vw', justifyContent: 'center', alignItems: 'center'
        }}>
            Authenticating..
        </div>
    }

    return <div>
        {children}
    </div>
}
