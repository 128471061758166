import { appNameShort } from '../constants/constants';

export function isProduction() {
  const isProd = window.location.href.toLocaleLowerCase().includes(appNameShort.toLocaleLowerCase()) && !window.location.href.toLocaleLowerCase().includes('dev.')
    && !window.location.href.toLocaleLowerCase().includes('//localhost:');
  return isProd;
}
 
export function isLocalhost() {
  return !isProduction() && !window.location.href.includes('localhost');
}
 
export function isNewClientVersion() {
  return false;
}
