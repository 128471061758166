export const SpinnerInLogo = () => {
    return <div style={{
        width: '100vw', height: '100vh', textAlign: 'center', background: '#0b0f11',
        display: 'flex', flexDirection: 'row', gridGap: '5em', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center'
    }}>
        <img style={{ width: 200, marginTop: 3 }} src="/images/logo.svg" alt="logo_50hertz" />
    </div>
}

export const Spinner = (props: any) => {
    const { vertical } = props
    return <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
        <div style={{ position: 'relative', width: 200, display: 'flex', flexDirection: vertical && 'column', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
            <img className="spinner" style={{ width: 200, marginTop: 3 }} src="/images/placeholder_logo.svg" alt="logo_emc2" />
            <div>Loading...</div>
        </div>
    </div>
}